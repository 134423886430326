<template>
  <div class="flex flex-col w-full h-full max-h-full p-4 space-y-4 overflow-y-scroll">
    
    <portal to="topbar-left">
      <div
        @click="goBack"
        class="relative font-bold"
        key="info-drugcombos-back"
        :class="{
          'text-white': isCheckedIn,
          'text-brand-dark': !isCheckedIn,
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 22"
          class="w-6 h-6"
        >
          <g
            fill="currentColor"
            fill-rule="evenodd"
            transform="translate(-4 -1)"
          >
            <path
              d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z"
            />
          </g>
        </svg>
      </div>
    </portal>

    <div class="px-6 py-6 text-sm leading-normal prose text-center break-words bg-white rounded-lg sm:prose-sm max-w-none">
      
      <h1>{{ $t('pages.partners') }}</h1>

      <h2>{{ $t('partners.title--developed-by') }}</h2>

      <div class="grid grid-cols-2 justify-items-stretch">
        <a href="https://www.itg.be/" target='blank' rel="noopener" class="mx-auto justify-self-auto"> 
          <img class="w-24" src="https://budd.be/wp-content/uploads/2022/02/logo-ITG.jpg" title="[logo ITG]">
        </a>
        <a href="https://www.uantwerpen.be" target='blank' rel="noopener" class="mx-auto justify-self-auto"> 
          <img class="w-24" src="https://budd.be/wp-content/uploads/2022/02/logo-UA.jpg" title="[logo UA]">
        </a>
      </div>

      <h2>{{ $t('partners.title--substantive-support-from') }}</h2>
      <div class="grid grid-cols-2 justify-items-stretch">
        <a href="https://www.sensoa.be/" target='blank' rel="noopener" class="mx-auto justify-self-auto"> 
          <img class="w-24" src="https://budd.be/wp-content/uploads/2022/02/logo-Sensoa.jpg" title="[logo Sensoa]">
        </a>
        <a href="https://www.vad.be/" target='blank' rel="noopener" class="mx-auto justify-self-auto"> 
          <img class="w-24" src="https://budd.be/wp-content/uploads/2022/02/logo-VAD.jpg" title="[logo VAD]">
        </a>
      </div>

      <h2>{{ $t('partners.title--made-possible-by') }}</h2>
      <div class="grid grid-cols-2 justify-items-stretch">
        <a href="https://viivhealthcare.com/" target='blank' rel="noopener" class="mx-auto justify-self-auto"> 
          <img class="w-24" src="https://budd.be/wp-content/uploads/2022/02/viiv-logo.png" title="[logo viiv]">
        </a>
        <a href="https://www.gilead.com/" target='blank' rel="noopener" class="mx-auto justify-self-auto"> 
          <img class="w-24" src="https://budd.be/wp-content/uploads/2022/02/logo-Gilead.jpg" title="[logo Gilead]">
        </a>
        <a href="https://free-clinic.be/" target='blank' rel="noopener" class="mx-auto justify-self-auto"> 
          <img class="w-24" src="https://budd.be/wp-content/uploads/2022/02/logo-Free-Clinic.jpg" title="[logo free clinic]">
        </a>
        <a href="https://mainline.nl/page/home" target='blank' rel="noopener" class="mx-auto justify-self-auto"> 
          <img class="w-24" src="https://budd.be/wp-content/uploads/2022/03/MainlineLogo_Geel_RGB.png" title="[logo Mainline] ">
        </a>
        <a href="https://academieanderlecht.be/" target='blank' rel="noopener" class="mx-auto justify-self-auto"> 
          <img class="w-24" src="https://budd.be/wp-content/uploads/2022/04/ABKA-LOGO-2020-RGB_SCHERM-768x563-1.jpg" title="[logo Academie Beeldende Kunsten Anderlecht]">
        </a>
        <a href="https://www.vlaanderen.be" target='blank' rel="noopener" class="mx-auto justify-self-auto"> 
          <img class="w-24" src="https://budd.be/wp-content/uploads/2022/02/Vlaamse-overheid.png" title="[Vlaamse Overheid]">
        </a>
        <a href="https://tunity.be/" target='blank' rel="noopener" class="mx-auto justify-self-auto"> 
          <img class="w-24" src="https://budd.be/wp-content/uploads/2022/02/logo-Tunity.jpg" title="[Tunity]">
        </a>
      </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Partners",
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(["isCheckedIn"]),
  },
};
</script>